<template>
    <div v-loading="loading">
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>听书管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/audioBooks' }">音频书</el-breadcrumb-item>
                <el-breadcrumb-item>新增</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-form :rules="rules" ref="form" :model="form" style="padding: 48px 72px" label-position="right"
            label-width="auto">
            <el-form-item label="名称: " prop="name">
                <el-input v-model="form.name" style="width: 240px" autofocus clearable />
            </el-form-item>

            <el-form-item label="排序" prop="sort">
                <el-input-number v-model="form.sort" :min="1" size="mini" style="width: 240px"></el-input-number>
            </el-form-item>


            <el-form-item label="是否试听" prop="unlock">
                <el-radio v-model="form.unlock" :label="1">是</el-radio>
                <el-radio v-model="form.unlock" :label="0">否</el-radio>
            </el-form-item>

            <el-form-item label="选择文件：" label-position="left">
                <selectAudio @onSelect="onSelectItem" @onDelete="onDeleteItem"></selectAudio>
            </el-form-item>

            <el-form-item>
                <el-button size="mini" @click="$router.back()">取消</el-button>
                <el-button size="mini" :loading="bottomLoading" @click="submit" type="primary">确定</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { config } from '@/js/config'
import { mapActions } from 'vuex'
import selectAudio from '@/components/selectAudio'
export default {
    components: { selectAudio },
    data: () => ({
        audioUploadPath: "audio",
        loading: false,
        rules: {
            name: [{ required: true }],
            sort: [{ required: true }],
            unlock: [{ required: true }],
        },
        form: {
            sort: 1,
            unlock: 1
        },
        bottomLoading:false
    }),

    methods: {
        ...mapActions('hearBook', ['addAudioBooksSection']),
        onSelectItem(e){
            this.form.audio_id = e
        },
        onDeleteItem(e){
            this.form.audio_id = ""
        },
        async submit() {
            this.bottomLoading = true
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.addAudioBooksSection(this.form).then(res => {
                        if(res.res_info != 'ok') return
                        this.$message.success('创建成功!')
                        this.$router.go(-1)
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
            this.bottomLoading = false
        },
    },
    created() {
        this.form.audio_books_chapter_id = this.$route.params.planId
    },
}
</script>
